<template>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      space="56"
    >
      <v-container class="fill-height px-4 py-0">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="1200"
          width="100%"
        >
          <v-container>
            <div class="d-flex justify-start">
            <base-section-heading
              :title="$t('contactUs')"
              style="width: max-content"
              :align="$vuetify.breakpoint.width >= 768 ? 'left' : 'center'"
            />
            </div>
          </v-container>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <v-btn-toggle
              v-model="toggle_exclusive"
              multiple
              class="toggle_group_btn"
              @change="selectButton"
            >
              <v-container class="mt-lg-12">
                <v-row>
                  <v-col
                    cols="4"
                    sm="4"
                    md="4"
                  >
                    <div class="d-flex justify-center mx-auto ">
                      <v-btn
                        width="100%"
                        min-height="60"
                        class="btn_choice"
                      >
                        {{ $t('price') }}
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    sm="4"
                    md="4"
                  >
                    <div class="d-flex justify-center mx-auto ">
                      <v-btn
                        width="100%"
                        min-height="60"
                        class="btn_choice"
                      >
                        {{ $t('quality') }}
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    md="4"
                    sm="4"
                  >
                    <div
                      class="d-flex justify-center mx-auto"
                    >
                      <v-btn
                        width="100%"
                        min-height="60"
                        class="btn_choice"
                      >
                        {{ $t('timing') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-btn-toggle>
            <v-container>
              <v-row>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="form.email"
                    type="email"
                    :error-messages="errors.email"
                    :rules="[rules.required('email')]"
                    name="email"
                    counter
                    maxlength="128"
                    background-color="#101010"
                    outlined
                    placeholder="debra.holt@example.com"
                    filled
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="form.contact"
                    type="text"
                    name="contact"
                    counter
                    maxlength="128"
                    background-color="#101010"
                    outlined
                    :placeholder="$t('additional_contacts_placeholder')"
                    filled
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-textarea
                v-model="form.description"
                type="text"
                name="description"
                :error-messages="errors.description"
                :rules="[rules.required('description')]"
                outlined
                counter
                maxlength="10000"
                background-color="#101010"
                :placeholder="$t('request_description')"
                filled
              />
            </v-container>
            <v-container>
              <div
                class="add_file_contact"
                :style="{ 'background-color': color }"
                @dragleave="fileDragOut"
                @dragover="fileDragIn"
                @drop="handleFileInput"
              >
                <input
                  ref="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                  type="file"
                  name="file-input"
                  multiple
                  class="input_file"
                  @change="onChangeFileAttached"
                >
                <div
                  class="d-flex justify-center mx-auto"
                  style="margin-top: 5%"
                >
                  <div class="float-left">
                    <v-img
                      class="mx-4"
                      height="25px"
                      width="15px"
                      src="@/assets/skrepka.png"
                    />
                  </div>
                  <div>
                    <span
                      style="font-size: 21px; font-weight:400; text-transform: none;"
                    >
                      {{ $t('attach_file') }}
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-center mx-auto">
                  <span
                    style="font-size: 14px; font-weight:400; text-transform: none;"
                  >{{ $t('attach_file_description') }}</span>
                </div>
                <div style="text-align: center">
                  <span
                    v-for="(file, index) in files"
                    :key="index"
                  >
                    {{ file.name }} ({{ file.size }} b)
                    <!--    <button @click="removeFile(index)" title="Remove">X</button>-->
                  </span>
                </div>
              </div>
            </v-container>
            <v-container class="fill-height py-0">
              <v-row>
                <v-col
                  lg="2"
                  sm="6"
                  md="6"
                >
                  <v-btn
                    width="100%"
                    min-height="60"
                    :loading="loading"
                    :disabled="loading || !valid"
                    class="btn_more"
                    type="submit"
                  >
                    {{ $t('send') }}
                  </v-btn>
                </v-col>
                <v-col
                  lg="2"
                  sm="6"
                  md="6"
                >
                  <v-btn
                    class="btn_more"
                    width="100%"
                    min-height="60"
                    @click="remove()"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-responsive>
      </v-container>
      <v-dialog
        v-model="successVisible"
        persistent
        max-width="466"
      >
        <v-card
          color="white"
          style="padding-bottom: 10%;padding-top: 10%;"
        >
<!--          <v-btn-->
<!--            icon-->
<!--          >-->
<!--            <v-icon>mdi-close</v-icon>-->
<!--          </v-btn>-->
          <div
            class="d-flex align-center justify-center"
          >
            <img
              class="d-flex align-center justify-center"
              :src="require('@/assets/img_success.svg')"
              alt=""
            >
          </div>
          <div
            class="d-flex align-center justify-center"
          >
            <span class="text_success">
              Success
            </span>
          </div>
          <div
            class="d-flex align-center justify-center"
          >
            <span class="text_content">
              Check your email for the offer confirmation.
            </span>
          </div>
          <div
            class="d-flex align-center justify-center"
          >
            <v-card-actions class="align-center">
              <v-btn
                class="btn_success"
                @click="closeSuccess"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </base-section>
  </v-theme-provider>
</template>

<script>
  import axios from 'axios'
  import { api } from '../../config'
  import Form from '../../mixins/form'
  export default {
    name: 'SectionContactUs',
    mixins: [Form],
    data () {
      return {
        toggle_exclusive: [0],
        files: [],
        color: 'black',
        loading: false,
        price: false,
        quality: false,
        timing: false,
        successVisible: false,
        form: {
          email: null,
          contact: null,
          description: null,
        },
      }
    },
    methods: {
      selectButton () {
        if (this.toggle_exclusive.length === 3) {
          this.toggle_exclusive.shift()
        }
      },
      onChangeFileAttached () {
        this.files = [...this.$refs.file.files]
        console.log('onChangeFileAttached: ' + JSON.stringify(this.files))
        this.files.forEach(element => console.log(JSON.stringify(element.name)))
        this.color = '#444444'
      },
      remove () {
        this.files = []
        this.color = 'black'
        this.form.email = ''
        this.form.description = ''
        this.form.contact = ''
        this.price = false
        this.quality = false
        this.timing = false
        this.$refs.form.reset()
        this.toggle_exclusive = [0]
      },
      submit () {
        this.toggle_exclusive.forEach((items) => {
          if (items === 0) {
            this.price = true
          } else if (items === 1) {
            this.quality = true
          } else if (items === 2) {
            this.timing = true
          }
        })
        if (this.$refs.form.validate()) {
          this.loading = true
          const formData = new FormData()
          if (this.files.length > 0) formData.append('file', this.files[0])
          formData.append('price', this.price ? '1' : '0')
          formData.append('quality', this.quality ? '1' : '0')
          formData.append('timing', this.timing ? '1' : '0')
          formData.append('email', this.form.email)
          formData.append('additional_contacts', this.form.contact)
          formData.append('text', this.form.description)
          axios.post(api.path('requests.add'), formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(res => {
              this.successVisible = true
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
      handleFileDrop (e) {
        const droppedFiles = e.dataTransfer.files
        if (!droppedFiles) return;
        ([...droppedFiles]).forEach(f => {
          this.files.push(f)
        })
      },
      handleFileInput (event) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChangeFileAttached()
      },
      removeFile (fileKey) {
        this.files.splice(fileKey, 1)
      },
      fileDragIn () {
        this.color = '#2c2c2c'
      },
      fileDragOut () {
        this.color = '#272727'
      },
      closeSuccess () {
        this.successVisible = false
        this.loading = false
        this.files = []
        this.color = 'black'
        this.form.email = ''
        this.form.description = ''
        this.form.contact = ''
        this.price = false
        this.quality = false
        this.timing = false
        this.$refs.form.reset()
      },
    },
    provide: {
      heading: { align: 'center' },
    },
  }
</script>
<style lang="sass">
  .btn_choice:active
    font-size: 18px !important
    color: #FFFFFF !important
  .btn_choice.v-btn--active:hover::before, .btn_choice.v-btn--active::before
    border: 2px solid #0A8C4C !important
    background:  #101010 !important
    opacity: 1 !important
    color: white !important
  .add_file_contact
    border: 2px dashed #828282
    box-sizing: border-box
  .btn_more
    border: 2px solid #0A8C4C !important
    background: transparent
  .toggle_group_btn
    width: 100%
  .btn_choice
    background-color: #101010 !important
  .file-wrapper
    text-align: center
    width: 300px
    height: 5em
    vertical-align: middle
    display: table-cell
    position: relative
    overflow: hidden
    background: gray
  /*.file-wrapper input*/
  /*  position: absolute*/
  /*  top: 0*/
  /*  right: 0*/
  /*  cursor: pointer*/
  /*  opacity: 0.0*/
  /*  filter: alpha(opacity=0)*/
  /*  font-size: 300px*/
  /*  height: 200px*/
  .add_file_contact
    border: 1px dashed #828282
    box-sizing: border-box
    position: relative
    height: 178px
  .input_file
    font-size: 14px
    font-weight: 400
    text-transform: none
    position: absolute
    height: 100%
    width: 100%
  input[type='file']
    opacity: 0
  .text_content
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 22px
    text-align: center
    color: #828282
  .text_success
    font-style: normal
    font-weight: 600
    font-size: 35px
    line-height: 43px
    color: #000000
  .btn_success
    width: 105.8px
    height: 46px
    color: #FFFFFF
    background: #0A8C4C !important
    border: 2px solid #0A8C4C
</style>
